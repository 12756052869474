import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Datetime from 'react-datetime';
import emailjs from 'emailjs-com';



import "react-datetime/css/react-datetime.css";

class Mozocarz extends React.Component {


  
  constructor(props) {

    super(props);

    this.myRef = React.createRef();
    this.timeRef = React.createRef();
    this.state = {


      input: {},
      errors: {},
      pick: "",
      drop: "",
      datetime: "",
      confirm: ""

    };


    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }




  setPickup(e) {


  }

  setDrop(e) {

  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ "confirm": "Please wait while your request is being submitted." })
    if (this.validate()) {
      console.log(this.state);
      let input = {};
      //input["phone"] = "";
      this.setState({ input: input });
      emailjs.sendForm('service_8hxicjl', 'template_7psmhoc', this.myRef.current , '_njq9zQpqzU6EAxjV')
        .then((result) => {
          this.myRef.current.reset();
          //this.setState({pick : "" , drop : ""})
          this.setState({ "confirm": "Your booking has been confirmed! We will reach you soon." })


        }, (error) => {
          this.setState({ "confirm": "There is a technical problem your booking has not been confimed." })
          console.log(error.text);
        });
    }
  }

  validate() {
    let isValid = true;
    let input = this.state.input;
    let errors = {};

    if (!input["phone"]) {
      isValid = false;
      errors["Mobilenumber"] = "Please enter your Mobile Number.";
    }

    if (typeof input["phone"] !== "undefined") {

      var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
      if (!pattern.test(input["phone"])) {
        isValid = false;
        errors["Mobilenumber"] = "Please Enter Number Only";
      } else if (input["phone"].length != 10) {
        isValid = false;
        errors["Mobilenumber"] = "Please enter valid  Mobile Number.";
      }
    }



    this.setState({
      errors: errors
    });

    console.log(this.pickRef)
    return isValid;
  }

  render() {
    return (


      <div class="slider_form">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item " role="presentation">
            <a style={{ color: "#808080" }} class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Local</a>
          </li>
          <li class="nav-item" role="presentation">
            <a style={{ color: "#808080" }} class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Outstation</a>
          </li>
          <li class="nav-item" role="presentation">
            <a style={{ color: "#808080" }} class="nav-link" id="contact-tab" data-toggle="tab" data-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Airport Transfer</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <form ref={this.myRef} onSubmit={this.handleSubmit}>

              <input type="hidden" name="pickup" value={this.state.pick} />
              <input type="hidden" name="drop" value={this.state.drop} />
              <input type="hidden" name="datetime" value={this.state.datetime} />

              <label>
                <select name="type" onChange={this.handleChange}>
                <option value="">--Please choose a vehicle--</option>
                <option value="Compact">Compact</option>
               <option value="Sedan">Sedan</option>
                <option value="Ertiga">Ertiga</option>
                <option value="Innova">Innova</option>
                <option value="Innova Crysta">Innova Crysta</option>
                <option value="Tempo Traveller">Tempo Traveller</option>
                </select>
              </label>
              <br />
              <GooglePlacesAutocomplete value="{this.state.pick}" selectProps={{
                placeholder: 'Type your pickup location',
                onChange: (object) => { this.setState({ pick: object.label }) }
              }} apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
                componentRestrictions: {
                  country: ['in'],
                }
              }}

              />
              <br />
              <GooglePlacesAutocomplete value="{this.state.drop}" selectProps={{
                placeholder: 'Type your drop location',
                onChange: (object) => { this.setState({ drop: object.label }) }
              }} label="Select your source location" apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
                componentRestrictions: {
                  country: ['in'],
                }
              }} />
              <br />
              <div className="App">
                <Datetime
                  onChange={(date) => { this.setState({ datetime: date.toDate() }) }}
                  inputProps={{

                    placeholder: 'Select the date',
                    required: true
                  }}
                />
              </div>
              <input type="text" name="name" placeholder="Enter your name " />
              <input type="text" name="phone" placeholder="Enter Phone Number" value={this.state.input.phone} onChange={this.handleChange} />
              <div className="text-danger mrgnbtn">{this.state.errors.Mobilenumber}</div>
              <input type="submit" value="Submit" />
            </form>
            <div className="text-warning mrgnbtn">{this.state.confirm}

            </div>

          </div>
          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><form ref={this.myRef} onSubmit={this.handleSubmit}>

            <input type="hidden" name="pickup" value={this.state.pick} />
            <input type="hidden" name="drop" value={this.state.drop} />
            <input type="hidden" name="datetime" value={this.state.datetime} />

            <label>
              <select name="type" onChange={this.handleChange}>
              <option value="">--Please choose a vehicle--</option>
              <option value="Compact">Compact</option>
               <option value="Sedan">Sedan</option>
                <option value="Ertiga">Ertiga</option>
                <option value="Innova">Innova</option>
                <option value="Innova Crysta">Innova Crysta</option>
                <option value="Tempo Traveller">Tempo Traveller</option>
              </select>
            </label>
            <br />
            <GooglePlacesAutocomplete value="{this.state.pick}" selectProps={{
              placeholder: 'Type your pickup location',
              onChange: (object) => { this.setState({ pick: object.label }) }
            }} apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
              componentRestrictions: {
                country: ['in'],
              }
            }}

            />
            <br />
            <GooglePlacesAutocomplete value="{this.state.drop}" selectProps={{
              placeholder: 'Type your drop location',
              onChange: (object) => { this.setState({ drop: object.label }) }
            }} label="Select your source location" apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
              componentRestrictions: {
                country: ['in'],
              }
            }} />
            <br />
            <div className="App">
              <Datetime
                onChange={(date) => { this.setState({ datetime: date.toDate() }) }}
                inputProps={{

                  placeholder: 'Select the date',
                  required: true
                }}
              />
            </div>
            <input type="text" name="name" placeholder="Enter your name " />
            <input type="text" name="phone" placeholder="Enter Phone Number" value={this.state.input.phone} onChange={this.handleChange} />
            <div className="text-danger mrgnbtn">{this.state.errors.Mobilenumber}</div>
            <input type="submit" value="Submit" />
          </form>
            <div className="text-warning mrgnbtn">{this.state.confirm}

            </div></div>
          <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab"><form ref={this.myRef} onSubmit={this.handleSubmit}>

            <input type="hidden" name="pickup" value={this.state.pick} />
            <input type="hidden" name="drop" value={this.state.drop} />
            <input type="hidden" name="datetime" value={this.state.datetime} />

            <label>
              <select name="type" onChange={this.handleChange}>
              <option value="">--Please choose a vehicle--</option>
              <option value="Compact">Compact</option>
               <option value="Sedan">Sedan</option>
                <option value="Ertiga">Ertiga</option>
                <option value="Innova">Innova</option>
                <option value="Innova Crysta">Innova Crysta</option>
                <option value="Tempo Traveller">Tempo Traveller</option>
              </select>
            </label>
            <br />
            <GooglePlacesAutocomplete value="{this.state.pick}" selectProps={{
              placeholder: 'Type your pickup location',
              onChange: (object) => { this.setState({ pick: object.label }) }
            }} apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
              componentRestrictions: {
                country: ['in'],
              }
            }}

            />
            <br />
            <GooglePlacesAutocomplete value="{this.state.drop}" selectProps={{
              placeholder: 'Type your drop location',
              onChange: (object) => { this.setState({ drop: object.label }) }
            }} label="Select your source location" apiKey="AIzaSyB484jOvuqEdY03qvyMNKY1ukb51Fw4HOE"  autocompletionRequest={{
              componentRestrictions: {
                country: ['in'],
              }
            }} />
            <br />
            <div className="App">
              <Datetime
                onChange={(date) => { this.setState({ datetime: date.toDate() }) }}
                inputProps={{

                  placeholder: 'Select the date',
                  required: true
                }}
              />
            </div>
            <input type="text" name="name" placeholder="Enter your name " />
            <input type="text" name="phone" placeholder="Enter Phone Number" value={this.state.input.phone} onChange={this.handleChange} />
            <div className="text-danger mrgnbtn">{this.state.errors.Mobilenumber}</div>
            <input type="submit" value="Submit" />
          </form>
            <div className="text-warning mrgnbtn">{this.state.confirm}

            </div></div>
        </div>



      </div>
    );
  }
}
export default Mozocarz;